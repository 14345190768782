import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary';
import CryptoJS from 'crypto-js';
import { useUserStore } from '@/stores/useUserStore';


const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert" className="flex justify-center mt-10 font-poppins text-black">
      <div className="w-fit text-center space-y-4">
        <p>Oops!! Something went wrong.</p>
        <button className="border border-reviewBg border-solid p-1 rounded-lg" onClick={resetErrorBoundary}>Try again</button>
      </div>
    </div>
  );
};

const ErrorBoundary: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { userInfo, getUser } = useUserStore();
  const [userData, setUserData] = useState(getUser());
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    setUserData(getUser());
  }, [getUser, userInfo])

  const logErrorToSlack = async (error: Error) => {
    const body = {
      data: {
        error: error.message,
        path: window.location.pathname,
        uid: userData.uuid,
        user_id: userData.user_id,
      },
      timestamp: new Date().getTime(),
    };
  
    try {
      await axios.post(
        '/api/report',
        {data: CryptoJS.AES.encrypt(JSON.stringify(body), process.env.NEXT_PUBLIC_KEY || '').toString()}
      )
    } catch (error: any) {

    }
  };

  const handleError = async (error: Error) => {
    await logErrorToSlack(error);

    if (retryCount < 2) {
      setRetryCount((prev) => prev + 1);
      window.location.reload(); // 自動嘗試重新整理頁面
    }
  };

  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={handleError}
      onReset={() => setRetryCount(0)} // 重置重試計數
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
